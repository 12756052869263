import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
//import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
//import ProcessOne from '../component/process/ProcessOne';
//import AboutThree from '../component/about/AboutThree';
//import AboutFour from '../component/about/AboutFour';
import AboutFive from '../component/about/AboutFive';
////import AboutOne from '../component/about/AboutOne';
//import AboutTwo from '../component/about/AboutTwo';
import BannerOne from '../component/banner/BannerOne';
import BannerSix from '../component/banner/BannerSix';
//import { AnimationOnScroll } from 'react-animation-on-scroll';
import ProjectOne from '../component/project/ProjectOne';


const AboutUs = () => {

    return (
        <>
        <SEO title="About us" />
        <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BannerOne/>
                {/*<BcrumbBannerOne 
                title="About Us"
                paragraph ="We are supported by a dynamic, energetic, and experienced team that is highly motivated to consistently exceed client project requirements and expectations, regardless of size. Timely delivery, rigorous quality standards, and robust security measures are integral to our business practices.
                "
                
                styleClass="thumbnail-4"
                mainThumb="/images/others/aboutus.jpg"
                />*/}
                 <AboutFive thumb="/images/about/vision.png" title="" subtitle1="Vision and Mission" subtitle2="Our vision is to be a customer-centric organization known for transforming businesses with innovative IT solutions. 
                 " subtitle3="Our mission is to provide reliable, high-quality services that make a positive, long-term impact on our clients' enterprises."/>
                <ProjectOne parentClass="bg-color-light"/>
                <AboutFive thumb="/images/about/Ourbusiness.png" title="" subtitle1="Our Business" subtitle2="Specializing in SAP implementation, we have delivered critical solutions to a diverse range of business customers across various industries, including Manufacturing, Retail & Consumer Products, Food & Beverage, Pharma & Life Sciences, and High-tech & Electronics. Our expertise encompasses end-to-end consulting for full-life cycle SAP implementations, as well as ongoing support for post-implementation maintenance. We have extensive experience with SAP application packages such as ECC, S/4 HANA, CRM, BI, and SEM. Additionally, we offer our proprietary ERP solution, iON ERP. 
                 " subtitle3=""/>
               <BannerSix/>
              
               
               
                <CtaLayoutOne />
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default AboutUs;