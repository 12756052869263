import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';
import ProcesstData from "../../data/process/ProcessData.json";
import Tilty from 'react-tilty';

const getProcesstData = ProcesstData;

const ProcessOne = (processname) => {

    const moduledata = getProcesstData.filter((data)=>data.id == processname.processname)[0]
    
    return (
        
        <div className="section section-padding bg-color-light pb--70">
            <SectionTitle 
                subtitle={moduledata.subtitle}
                title={moduledata.title}
                description={moduledata.description}
                textAlignment="center"
                textColor="mb--90"
            />
            <div className="container">
                {moduledata.details.map((data) => (
                    <div key={data.id} className={`process-work ${(data.id % 2  === 0) ? "content-reverse" : ""}`}>
                        
                       
                            
                            <div className="thumbnail ">
                                <img src={process.env.PUBLIC_URL + data.thumb}  className="image-fluid" alt="Responsive image" width="500px" style={{height:"400px"}}/>
                            </div>
                       
                      
                    <div className="content ">
                        <span className="subtitle">{data.subtitle}</span>
                        <h3 className="title">{data.title}</h3>
                        <p>{data.paragraph}</p>
                        <ul>
                        {data.datalistitems.map((data) => (
                        <li className=" mt-3" key={data.id}>
                            {data.label}
                        </li>
                    ))}
                        </ul>
                    </div>

                            
                        
                </div>
                ))}
            </div>
            <ul className="shape-group-17 list-unstyled">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/bubble-24.png"} alt="Bubble" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-23.png"} alt="Bubble" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/line-4.png"} alt="Line" /></li>
                <li className="shape shape-4"><img src={process.env.PUBLIC_URL + "/images/others/line-5.png"} alt="Line" /></li>
                <li className="shape shape-5"><img src={process.env.PUBLIC_URL + "/images/others/line-4.png"} alt="Line" /></li>
                <li className="shape shape-6"><img src={process.env.PUBLIC_URL + "/images/others/line-5.png"} alt="Line" /></li>
            </ul>
        </div>
    )
}

export default ProcessOne;