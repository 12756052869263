import React from 'react';
import { Link } from 'react-router-dom';
import Offcanvas from 'react-bootstrap/Offcanvas'
import {  FaPhone } from "react-icons/fa";
//import { FaXTwitter } from "react-icons/fa6";
//import { FaAngleDown } from 'react-icons/fa6';


const OffcanvasMenu = ({offcanvasShow, offcanvasHide}) => {
    return (
        <Offcanvas show={offcanvasShow} onHide={offcanvasHide} placement="end" className="header-offcanvasmenu">
            <Offcanvas.Header closeButton></Offcanvas.Header>
            <Offcanvas.Body>
                <form action="#" className="side-nav-search-form">
                    <div className="form-group">
                        <input type="text" className="search-field" name="search-field" placeholder="Search..." />
                        <button className="side-nav-search-btn"><i className="fas fa-search"></i></button>
                    </div>
                </form>
                <div className="row ">
                    <div className="col-lg-5 col-xl-6">
                        <ul className="main-navigation list-unstyled">
                            <li><Link to={process.env.PUBLIC_URL + "/home"}>Home</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + "/about-us"}>About Us</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + "/services"}>Services</Link></li>
                           

                            <li><Link to={process.env.PUBLIC_URL + "/features"}>Features</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + "/corporate-agency"}>Contact Us</Link></li>
                        </ul>
                    </div>
                    <div className="col-lg-7 col-xl-6">
                        <div className="contact-info-wrap">
                            <div className="contact-inner">
                                <address className="address">
                                    <span className="title">Contact Information</span>
                                    <p>Flat No:612,6 th Floor <br /> Babukhan Estate ,Basheerbagh <br /> Hyderabad-500029</p>
                                </address>
                                <address className="address">
                                    <span className="title">We're Available . Call Now.</span>
                                    <a href="tel:8884562790" className="tel"><FaPhone /> 040-23730087</a>
                                    <a href="tel:12125553333" className="tel"><FaPhone /> 040- 23730147</a>
                                    <a href="tel:12125553333" className="tel"><FaPhone /> 9000334128</a>
                                </address>
                            </div>
                            {/*<div className="contact-inner">
                                <h5 className="title">Find us here</h5>
                                <div className="contact-social-share">
                                    <ul className="social-share list-unstyled">
                                        <li>
                                            <a href="https://facebook.com/"><FaFacebookF /></a>
                                        </li>

                                        <li>
                                            <a href="https://twitter.com/"><FaXTwitter /></a>
                                        </li>
                                        <li>
                                            <a href="https://www.behance.net/"><FaBehance /></a>
                                        </li>
                                        <li>
                                            <a href="https://www.linkedin.com/"><FaLinkedinIn /></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>*/}
                        </div>
                    </div>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default OffcanvasMenu;
