import React from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Link } from 'react-router-dom';
import SectionTitle from '../../elements/section-title/SectionTitle';


const BannerSix = ({title,paragraph,thumb}) => {
    return (
        
  

<div className="section-padding-equal bg-color-light">
<div className="container">
   
       
    
    <div className=" align-items-center">
        <div className="about-team">
   <div className="section-heading" >
            <h1 className="title" style={{fontSize:"38px"}}>Why Choose Our Software?</h1>
            
            <p>Our software is designed to be user-friendly and highly adaptable, ensuring that your business operations run smoothly without needing extensive technical support. With both online and offline capabilities, multi-platform access, and advanced customization options, we provide the tools you need to stay ahead in a competitive market.</p>
           
            {/*<Link to="#" className="axil-btn btn-large btn-fill-primary">Our Team</Link> */}
        </div>
        </div>
   
    </div>
</div>
</div>
       
    )
}

export default BannerSix;