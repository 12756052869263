import React from 'react';
import FormOne from '../contact/FormOne';
import Accordion from 'react-bootstrap/Accordion';
import { FaCompress, FaCode, FaGlobe } from 'react-icons/fa';

const AboutOne = () => {
    return (
        <section className="section section-padding-equal bg-color-light">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="about-us why-choose-us">
                            <div className="section-heading heading-left mb-0">
                                <span className="subtitle"></span>
                                <h2 className="title mb--40">We do design, code &amp; develop.</h2>
                                <p>At IT iON solutions Limited, we specialize in bringing your ideas to life through a comprehensive approach:</p>
                                <Accordion defaultActiveKey="1">
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header><FaCompress /> Design</Accordion.Header>
                                    <Accordion.Body>
                                    Our creative team crafts intuitive and visually engaging designs, ensuring a seamless user experience that elevates your brand.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header><FaCode /> Code</Accordion.Header>
                                    <Accordion.Body>
                                    We deliver clean, efficient, and scalable code, guaranteeing robust functionality and optimal performance across all platforms.
                                  
                                    </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                   
                                
                                <Accordion.Header><FaGlobe /> Development</Accordion.Header>
                                    <Accordion.Body>
                                    From concept to deployment, we develop tailored solutions that meet your unique business needs, driving innovation and growth.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>







                               
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-5 col-lg-6 offset-xl-1">
                        <div className="contact-form-box">
                            <h3 className="title">Get a free Keystroke quote now</h3>
                           <FormOne />
                        </div>
                    </div>
                </div>
            </div>
            <ul className="shape-group-6 list-unstyled">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/bubble-7.png"} alt="Bubble" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-4.png"} alt="line" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/line-5.png"} alt="line" /></li>
            </ul>
        </section>
    )
}

export default AboutOne;