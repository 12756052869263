import React from 'react';
//import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
//import { FaXTwitter } from "react-icons/fa6";
//import { Link } from 'react-router-dom';
//import Tilty from 'react-tilty';


const BannerFour = () => {
    return (
        <div className="banner banner-style-4">
            <div className="container">
                <div className="banner-content">
                    <span className='subtitle'>Enhancing Your Business with Versatile and Robust Solutions</span>
                    <h1 className="title">Unleash the Full Potential of Our Software</h1>
                    <p>At IT iON solutions, our software is designed to be highly customizable, catering to the unique needs of your business with zero dependency on a technical team. Explore the additional features that set us apart and drive your success.</p>
                    
                </div>
                <div className="banner-thumbnail">
                    <div className="large-thumb">
                       
                            <img src={process.env.PUBLIC_URL + "/images/banner/banner-thumb-7.png"} alt="Shape" />
                       
                    </div>
                </div>
             
            </div>
            <ul className="list-unstyled shape-group-19">
                <li className="shape shape-1">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-29.png"} alt="Bubble" />
                </li>
                <li className="shape shape-2">
                    <img src={process.env.PUBLIC_URL + "/images/others/line-7.png"} alt="Bubble" />
                </li>
            </ul>
        </div>
    )
}

export default BannerFour;