import React from 'react';


const SectionTitle = ({subtitle="", title, description, textAlignment="left", textColor="var(--color-light)"}) => {
    return (
        <div className={`about-team section-heading ${textAlignment} ${textColor}`}>
            <div className="subtitle" dangerouslySetInnerHTML={{__html: subtitle}}></div>
            <h1 className="title" dangerouslySetInnerHTML={{__html: title}}></h1>
            <p dangerouslySetInnerHTML={{__html:description}} ></p>
        </div>
    )
}

export default SectionTitle;