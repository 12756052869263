import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../../elements/section-title/SectionTitle';


const AboutFive = ({thumb,title,subtitle1,subtitle2,subtitle3}) => {
    return (
      <div className="section-padding-equal">
            <div className="container">
                <div className="row align-items-stretch">
                    <div className=' col-md-6 d-flex align-items-center '>
                    <div className="about-team">
                    
                  
                    <img src={process.env.PUBLIC_URL + thumb} alt="thumbnail" className='img-fluid full-height-image '  />
               
                
            </div>

                    </div>
                
                <div className="col-md-6 d-flex align-items-center">
                    <div className="about-team">
               <div className="section-heading heading-left">
                        <h1 className="title">{title}</h1>
                        <h2>{subtitle1}</h2>  
                        <p>{subtitle2}</p>
                        <p>{subtitle3}</p>
                        {/*<Link to="#" className="axil-btn btn-large btn-fill-primary">Our Team</Link> */}
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}

export default AboutFive;