import React from 'react';
import CountUp from 'react-countup';
import TrackVisibility from 'react-on-screen';

const Data = [
    {  
        id: 1,
        light_icon: "/images/icon/icon-7.png",
        dark_icon: "/images/icon/icon-10.png",
        countNum: 15,
        text: "Low Cost",
        detail:"Best software solution with complete flexibility at an affordable price"
    },
    {  
        id: 2,
        light_icon: "/images/icon/quickimplementation.png",
        dark_icon: "/images/icon/quickimplementation.png",
        countNum: 360,
        text: "Quick Implementation",
        detail:"Standard ERP implementation time is only couple of weeks"
    },
    {  
        id: 3,
        light_icon:"/images/icon/icon-37.png",
        dark_icon: "/images/icon/icon-37.png",
        countNum: 600,
        text: "Zero Dependency",
        detail:"Software is highly customizable for the unique needs of businesses with zero dependency on technical team"
    },
    {  
        id: 4,
        light_icon: "/images/icon/icon-42.png",
        dark_icon: "/images/icon/icon-42.png",
        countNum: 64,
        text: "latest Features",
        detail:"Online / Offline, Web enabled, Mobile enabled, WhatsApp, Office Integration and many more…"
    }
   
];

const CounterUp = ({colSize, layoutStyle, evenTopMargin}) => {

    return (
        <>
            {Data.map((data) => (
                <div className={`${colSize} ${(data.id % 2  === 0) ? evenTopMargin : ""}`} key={data.id} >
                    <div className={`counterup-progress ${layoutStyle}`} >

                        { 
                            layoutStyle === "counterup-style-2" ? 
                            <div className="icon">
                                <img className="dark-icon"  src={process.env.PUBLIC_URL + data.dark_icon} alt="Icon" />
                                <img className="light-icon"  src={process.env.PUBLIC_URL + data.light_icon} alt="Icon" />
                                
                            </div> : 
                            <div className="icon">
                                <img className="light-icon"  src={process.env.PUBLIC_URL + data.light_icon} alt="Icon" />
                            </div> 
                        }

                        {/*<div className="count-number h2">
                            <TrackVisibility once>
                                {({isVisible}) => (
                                    <span className="number count">
                                        {isVisible ? <CountUp end={data.countNum} duration={1} /> : null}
                                    </span>
                                )}  
                            </TrackVisibility>
                            <span className="symbol">+</span>
                        </div>*/}
                        <h6 className="title">{data.text}</h6>
                       
                        <h8 className="title">{data.detail}</h8>
                    </div>
                </div>
            ))}
        </>
    )
}



export default CounterUp;