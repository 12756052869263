import React from 'react';
//import LocationData from '../../data/contact/LocationData.json';

//const allData = LocationData;


const ContactLocation = () => {
    return (
        <>
           
                <div className="col-lg-6 col-sm-6" >
                    <div className="office-location">
                       
                        <div className="content">
                        <h4 className="title">India Operations</h4>
                            <h5 className="title">Flat No:612,6 th Floor</h5>
                            <h5 className="title">Babukhan Estate</h5>
                            <h5 className="title">Basheerbagh</h5>
                            <h5 className="title">Hyderabad-500029</h5>
                            
                           
                        </div>
                    </div>
                  
                </div>
                <div className="col-lg-6 col-sm-6">

                   
<div className="office-location">
   
    <div className="content">
    <h4 className="title">USA Operations</h4>
        <h5 className="title">7460</h5>
        <h5 className="title">Warren Parkway Suite 100 -#3978</h5>
        <h5 className="title">Frisco</h5>
        <h5 className="title">TX 75034</h5>
       
    </div>
</div>
</div>
            
        </>
    )
}

export default ContactLocation;